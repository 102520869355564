import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import VueToast from 'vue-toast-notification';
import VueScrollTo from 'vue-scrollto';
import 'vue-toast-notification/dist/theme-bootstrap.css';

let offset = -200;
if (window.innerWidth < 1000) {
  offset = -77;
}

Vue.use(VueScrollTo, {
  offset: offset,
})
Vue.use(VueToast);
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
